import React, {PropsWithChildren} from 'react';
import {LoginCommand, UserDto} from "./auth/AuthApiTypes";
import {useLogin} from "./auth/AuthApi";
import {setAuthState} from "./apiClients";

export const TOKEN_STORAGE = 'JWT_TOKEN_STORAGE'

export interface AuthState {
    isReady: boolean;
    isLogged: boolean;
    user?: UserDto | null;
    login: (command: LoginCommand) => Promise<void>;
    logout: () => void;
}

export const AuthContext = React.createContext<AuthState>({
    isReady: false,
    isLogged: false,
    login: ((_: LoginCommand) => Promise.resolve()),
    logout: (() => {
    })
});

export const useAuth = (): AuthState => {
    return React.useContext(AuthContext);
};

export const AuthProvider = ({children}: PropsWithChildren) => {
    const login = useLogin();
    const [isLogged, setLogged] = React.useState<boolean>(false);
    const [isReady, setReady] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<UserDto | null>(null);

    const onTokenChange = (token: string | null) => {
        setAuthState(token, logout);
    }

    const loginUser = (command: LoginCommand): Promise<void> =>
        login.mutateAsync(command).then(data => {
            localStorage.setItem(TOKEN_STORAGE, JSON.stringify(data));
            onTokenChange(data.token);
            setUser(data.user);
            setLogged(true);
        });

    const logout = () => {
        localStorage.removeItem(TOKEN_STORAGE)
        onTokenChange(null);
        setLogged(false)
        setUser(null);
    }


    React.useEffect(() => {
        (async () => {
            const token = localStorage.getItem(TOKEN_STORAGE);
            if (!token) {
                setReady(true);
                return;
            }
            try {
                const res = JSON.parse(token);
                onTokenChange(res.token);
                setUser(res.user)
                setLogged(true)
                setReady(true);
            } catch (e) {
                onTokenChange(null);
                setUser(null)
                setLogged(false)
                setReady(true);
            }
        })();
    }, []);

    if (!isReady) return null;

    return (
        <AuthContext.Provider value={{
            isReady,
            isLogged,
            user,
            login: loginUser,
            logout,
        }}>
            {children}
        </AuthContext.Provider>
    );
};