export const Logomark = (props: any) => {
  return (
      <img alt={'Icon'} src={require('../../images/180.png')} {...props} />
  )
}

export const Logo = (props: any) => {
  return (
      <img alt={'Logotype'} src={require('../../images/logo.png')} style={{maxWidth: '50vw', objectFit: 'contain'}} {...props} />
  )
}
