import React from "react";
import {Link, useMatch} from "react-router-dom";
import {ActivityDto} from "../../../providers/readingsTypes";
import EffortSquare from "../EffortSquare";
import dayjs from "dayjs";

interface ActivityMenuItemProps {
    activity: ActivityDto;
}

export const mapName = (name: string) => {
    return name.split("_")
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1, item.length).toLowerCase()).join(" ");
}

const ActivityMenuItem = ({activity}: ActivityMenuItemProps) => {
    const isActive = useMatch(`/dashboard/activities/${activity.id}`);

    return (
        <Link to={`/dashboard/activities/${activity.id}`} key={activity.id}>
            <li key={activity.id} className="flex gap-x-4 py-4">
                <EffortSquare effort={activity.effort} />
                <div className="min-w-0">
                    <p className={`text-sm font-semibold leading-6 ${isActive ? "text-metcon" : "text-gray-900"}`}>{mapName(activity.type)}</p>
                    <p className="text-xs font-medium text-gray-500">
                        By{' '}
                        <span className="text-gray-900">
                            {activity.user.firstName} {activity.user.lastName}
                        </span>{' '}
                        on {dayjs(activity.startDate).format("LLL")}
                    </p>
                </div>
            </li>
        </Link>
    );
}

export default ActivityMenuItem;