import Header from "../../components/dashboard/start/Header";
import WeeklySummary from "../../components/dashboard/start/WeeklySummary";

const Start = ({}) => {
    return (
        <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
                <Header />
                <WeeklySummary />
            </div>
        </main>
    );
}

export default Start;