import {Listbox, Transition} from "@headlessui/react";
import React from "react";
import {BoardDto} from "../../../providers/boardTypes";
import {useNavigate} from "react-router-dom";

const emptyGroupImage = require('../../../images/empty.png');

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const MobileBoardList = ({list}: { list?: BoardDto[] }) => {
    const [selected, setSelected] = React.useState<null | BoardDto>(null);
    const navigate = useNavigate();

    const onSelect = (board: BoardDto) => {
        setSelected(board);
        navigate("/dashboard/boards/" + board._id);
    }

    return (
        <div className="lg:hidden px-6 py-4">
            <Listbox value={selected} onChange={onSelect}>
                {({open}) => (
                    <>
                        <div className="relative mt-2">
                            <Listbox.Button
                                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <img src={selected ? selected.image : emptyGroupImage} alt=""
                     className="h-5 w-5 flex-shrink-0 rounded-full"/>
                <span className="ml-3 block truncate">{selected ? selected.name : "Select board"}</span>
              </span>
                                <span
                                    className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                {/*<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />*/}
              </span>
                            </Listbox.Button>

                            <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                <Listbox.Options
                                    className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {(list || []).map((board) => (
                                        <Listbox.Option
                                            key={board._id}
                                            // @ts-ignore
                                            className={({focus}) =>
                                                classNames(
                                                    focus ? 'bg-indigo-600 text-white' : '',
                                                    !focus ? 'text-gray-900' : '',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9',
                                                )
                                            }
                                            value={board}
                                        >
                                            {/*@ts-ignore*/}
                                            {({selected, focus}) => (
                                                <>
                                                    <div className="flex items-center">
                                                        <img src={board.image} alt=""
                                                             className="h-5 w-5 flex-shrink-0 rounded-full"/>
                                                        <span
                                                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                        >
                            {board.name}
                          </span>
                                                    </div>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                focus ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                            )}
                                                        >
                            {/*<CheckIcon className="h-5 w-5" aria-hidden="true" />*/}
                          </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </div>
    )
}

export default MobileBoardList;