import {Activity, HeartRateZoneType, HeartRateZoneTypeMap, ZoneColor} from "../../../providers/readingsTypes";
import Chart from "react-apexcharts";
import React from "react";
import dayjs from "dayjs";

export interface ActivityZoneGraphProps {
    activity: Activity;
}

const ActivityZoneGraph = ({activity}: ActivityZoneGraphProps) => {
    const chartData = {
        [HeartRateZoneType.REST]: activity.valueHolder.durations.REST || 0,
        [HeartRateZoneType.VERY_LIGHT]: activity.valueHolder.durations.VERY_LIGHT || 0,
        [HeartRateZoneType.LIGHT]: activity.valueHolder.durations.LIGHT || 0,
        [HeartRateZoneType.MODERATE]: activity.valueHolder.durations.MODERATE || 0,
        [HeartRateZoneType.HARD]: activity.valueHolder.durations.HARD || 0,
        [HeartRateZoneType.MAX]: activity.valueHolder.durations.MAX || 0,
    }

    const keys = Object.keys(chartData).filter(key => chartData[key as unknown as HeartRateZoneType]);
    const series = Object.values(chartData).filter(val => val);

    const options = {
        chart: {
            id: 'zoneChart',
        },
        colors: keys.map(key => ZoneColor[key as unknown as HeartRateZoneType]),
        labels: keys.map((key) => HeartRateZoneTypeMap[key as unknown as HeartRateZoneType]),
        tooltip: {
            y: {
                formatter: function (value: number) {
                    return dayjs(value * 1000).format("mm:ss")
                }
            }
        }
    };

    return (
        <>
            {/*@ts-ignore*/}
            <Chart options={options} series={series} type="donut" width={500} height={300}/>
        </>
    );
}

export default ActivityZoneGraph;