import BoardList from "../../components/dashboard/board/BoardList";
import {useParams} from "react-router-dom";
import SelectBoard from "../../components/dashboard/board/SelectBoard";
import Board from "../../components/dashboard/board/Board";

const Boards = (props: unknown) => {
    const {id} = useParams();
    return (
        <>
            <BoardList/>
            <main className="lg:pl-72">
                <div className="xl:pl-96">
                    <div className="flex items-center justify-center min-h-screen">
                        {id ? <Board id={id}/> : <SelectBoard/>}
                    </div>
                </div>
            </main>
        </>
    );
}

export default Boards;