import {useParams} from "react-router-dom";
import ActivityList from "../../components/dashboard/activity/ActivityList";
import SelectActivity from "../../components/dashboard/activity/SelectActivity";
import Activity from "../../components/dashboard/activity/Activity";

const Activities = () => {
    const {id} = useParams();

    return (
        <>
            <ActivityList/>
            <main className="lg:pl-72">
                <div className="xl:pl-32">
                    {id ? <Activity id={id}/> : <SelectActivity/>}
                </div>
            </main>
        </>
    );
}

export default Activities;