import React from "react";

export enum EffortSquareSize {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    BIG = 'BIG',
}

interface ActivityEffortSmallProps {
    effort: number;
    size?: EffortSquareSize;
}

const EffortSquare = ({effort, size = EffortSquareSize.SMALL}: ActivityEffortSmallProps) => {
    const fontSize = size === EffortSquareSize.SMALL
        ? "font-bold text-md"
        : size === EffortSquareSize.MEDIUM
            ? "font-bold text-xl"
            : "font-bold text-2xl";

    const squareSize = size === EffortSquareSize.SMALL
        ? "w-11 h-11"
        : size === EffortSquareSize.MEDIUM
            ? "w-13 h-13"
            : "w-16 h-16";

    return (
        <div className={`bg-gray-200 rounded-lg flex justify-center items-center ${squareSize}`}>
            <p className={`text-gray-800 ${fontSize}`}>{effort.toFixed(1)}</p>
        </div>

    );
}

export default EffortSquare;