import React from 'react';
import {QueryClientProvider} from "react-query";
import {queryClient} from "./providers/queryClient";
import {RouterProvider} from "react-router-dom";
import {router} from "./providers/router";
import {AuthProvider} from "./providers/authProvider";
import AlertProvider from "./providers/alertProvider";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(localizedFormat)
dayjs.extend(duration)

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AlertProvider>
                <AuthProvider>
                    <RouterProvider router={router}/>
                </AuthProvider>
            </AlertProvider>
        </QueryClientProvider>
    );
}

export default App;
