import {UserDto} from "./auth/AuthApiTypes";

export interface UserStatistics {
    userId?: string;
    maxHR?: number;
    maxEffort?: number;
    lastEffortUpdate?: Date;
}

export interface GroupDto {
    _id: string;
    name: string;
    image: string;
    description: string;
    joinCode: string;
    createdBy: string;
    usersCount: number;
    accessToActivity: boolean;
    accessToReadings: boolean;
}

export interface ActivityDto {
    id: string;
    day: string;
    type: ActivityType;
    status: ActivityStatus;
    effort: number;
    startDate: Date;
    endDate: Date;
    user: UserDto;
}

export interface Activity {
    _id: string;
    day: string;
    userId: string;
    type: ActivityType;
    effort: number;
    valueHolder: EffortValueHolder;
    startDate: Date;
    endDate: Date;
    readings: Reading[];
    status: ActivityStatus;
    locations: ActivityLocation[];
    user: UserDto;
    energy: number;
    distance: number;
}

export interface Reading {
    value: number;
    time: Date;
}

export interface ActivityLocation {
    latitude: number;
    longitude: number;
    altitude?: number;
    timestamp?: Date;
    speed?: number;
    speedAccuracy?: number;
}

export interface EffortValueHolder {
    lastReadingValue: number;
    lastReadingTime: Date;
    linearValues: ValueType;
    durations: ValueType;
    readingsSum: number;
    readingsCount: number;
    readingsMax: number;
    calculatedMax: number;
}

export type ValueType = {
    [key in HeartRateZoneType]?: number;
};

export enum HeartRateZoneType {
    REST = 'REST',
    VERY_LIGHT = 'VERY_LIGHT',
    LIGHT = 'LIGHT',
    MODERATE = 'MODERATE',
    HARD = 'HARD',
    MAX = 'MAX',
}

export enum ActivityType {
    FITNESS = 'FITNESS',
    BASKETBALL = 'BASKETBALL',
    RUNNING = 'RUNNING',
    SWIMMING = 'SWIMMING',
    BIKE = 'BIKE',
    OTHER = 'OTHER',
    WALK = 'WALK',
    YOGA = 'YOGA',
}

export enum ActivityStatus {
    PENDING = 'PENDING',
    PROCESSED = 'PROCESSED',
}

export const HeartRateZoneTypeMap = {
    [HeartRateZoneType.REST]: 'Rest',
    [HeartRateZoneType.VERY_LIGHT]: 'Zone 1',
    [HeartRateZoneType.LIGHT]: 'Zone 2',
    [HeartRateZoneType.MODERATE]: 'Zone 3',
    [HeartRateZoneType.HARD]: 'Zone 4',
    [HeartRateZoneType.MAX]: 'Zone 5',
}

export const ZoneColor = {
    [HeartRateZoneType.REST]: '#59A41D',
    [HeartRateZoneType.VERY_LIGHT]: "#88EDFF",
    [HeartRateZoneType.LIGHT]: "#125493",
    [HeartRateZoneType.MODERATE]: "#FEC106",
    [HeartRateZoneType.HARD]: "#DAA004",
    [HeartRateZoneType.MAX]: "#B68103",
}

export interface EffortDto {
    effort: number;
    recovery: number;
    day: string;
    optimalEffort: string;
}

export interface EffortLeaderboardDto {
    effort: number;
    recovery: number;
    user: UserDto;
}