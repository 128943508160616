import React, {Fragment, PropsWithChildren} from "react";
import {Transition} from '@headlessui/react'

export enum AlertType {
    DANGER = 'DANGER',
    SUCCESS = 'SUCCESS'
}

export interface AlertState {
    type?: AlertType;
    title?: string;
    message?: string;
    isVisible: boolean;
    showAlert: (title: string, message: string, type: AlertType) => void
}

const Context = React.createContext({
    isVisible: false,
    showAlert: ((_: string, __: string, ___: AlertType) => {})
});

export const useAlert = (): AlertState => {
    return React.useContext(Context);
};

const AlertProvider = ({children}: PropsWithChildren) => {
    const showAlert = (title: string, message: string, type: AlertType) => {
        setState(state => ({
            ...state,
            type,
            title,
            message,
            isVisible: true
        }))
        setTimeout(() => {
            setState(state => ({
                ...state,
                isVisible: false
            }))
        }, 3000);
    }

    const [state, setState] = React.useState<AlertState>({
        isVisible: false,
        showAlert
    })

    return (
        <Context.Provider
            value={{
                ...state,
            }}
        >
            {children}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={state.isVisible}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {/*<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />*/}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{state.title}</p>
                                        <p className="mt-1 text-sm text-gray-500">{state.message}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setState(state => ({
                                                    ...state,
                                                    isVisible: false
                                                }))
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            {/*<XMarkIcon className="h-5 w-5" aria-hidden="true" />*/}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </Context.Provider>
    )
}

export default AlertProvider;