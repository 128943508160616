import {Activity} from "../../../providers/readingsTypes";
import Chart from "react-apexcharts";
import React from "react";
import dayjs from "dayjs";

export interface ActivityChartProps {
    activity: Activity;
}

const ActivityChart = ({activity}: ActivityChartProps) => {

    const startTime = activity.readings[0]?.time;

    const options = {
        chart: {
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            id: 'activityChart',
        },
        xaxis: {
            tickAmount: 12,
            labels: {
                showDuplicates: false,
                formatter: function (value: string) {
                    return dayjs(value).diff(startTime, 'minute');
                }
            }
        },
        tooltip: {
            x: {
                formatter: function (value: number) {
                    return dayjs(activity.readings[value - 1].time).format("HH:mm")
                }
            },
            y: {
                formatter: function (value: number) {
                    return value + "bpm";
                }
            }
        }
    };
    const series = [{
        name: `${activity.user.firstName} ${activity.user.lastName}`,
        data: activity.readings?.map(el => ({
            x: el.time,
            y: el.value
        })) || [],
    }]

    return (
        <>
            {/*@ts-ignore*/}
            <Chart options={options} series={series} type="line" width={'100%'} height={400}/>
        </>
    )
}

export default ActivityChart;