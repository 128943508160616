import axios, {AxiosInstance} from "axios";

export const authClient = axios.create({
    baseURL: "https://auth.metconlovers.com/",
    timeout: 60000,
});

export const boardClient = axios.create({
    baseURL: "https://boards.metconlovers.com",
    timeout: 60000,
});

export const filesClient = axios.create({
    baseURL: "https://files.metconlovers.com/",
    timeout: 60000,
});

export const readingsClient = axios.create({
    baseURL: "https://effort.metconlovers.com/",
    timeout: 60000,
});


export const setAuthState = (token: string | null, logout: () => void) => {
    createAxiosResponseInterceptor(boardClient, token, logout);
    createAxiosResponseInterceptor(filesClient, token, logout);
    createAxiosResponseInterceptor(readingsClient, token, logout);
    createAxiosResponseInterceptor(authClient, token, logout);
}

const createAxiosResponseInterceptor = (axiosInstance: AxiosInstance, token: string | null, logout: () => void) => {
    axiosInstance.interceptors.response.clear()
    axiosInstance.interceptors.request.clear()

    axiosInstance.interceptors.request.use(
        (config) => {
            console.log(`[API Req] ${config?.baseURL} ${config.url}`)
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (data) => {
            return data;
        },
        (error) => {
            if(error?.response?.status === 401){
                logout();
            } else {
                return Promise.reject(error);
            }
        }
    );
}