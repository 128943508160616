import ActivityMenuItem from "./ActivityMenuItem";
import {useActivityList} from "../../../providers/readingsApi";

const ActivityList = () => {
    const {data} = useActivityList();

    return (
        <aside
            className="fixed bg-gray-50 inset-y-0 left-72 hidden w-96 overflow-y-auto border-r border-gray-200 py-6 md:block">
            <header
                className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 lg:px-8">
                <h2 className="text-base font-semibold leading-7">Recent activities</h2>
            </header>
            <div className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 lg:px-8">
                <div role="list" className="divide-y divide-gray-100">
                    {(data || []).map((activity) => (
                        <ActivityMenuItem activity={activity} key={activity.id}/>
                    ))}
                </div>
            </div>
        </aside>
    );
}

export default ActivityList;