import React from "react";
import {useActivityDetails} from "../../../providers/readingsApi";
import EffortSquare, {EffortSquareSize} from "../EffortSquare";
import {mapName} from "./ActivityMenuItem";
import dayjs from "dayjs";
import {AlertType, useAlert} from "../../../providers/alertProvider";
import ActivityChart from "./ActivityChart";
import ActivityZoneGraph from "./ActivityZoneGraph";
import ActivityDetails from "./ActivityDetails";

interface ActivityProps {
    id: string;
}

const Activity: React.FC<ActivityProps> = ({id}) => {
    const {showAlert} = useAlert()
    const {data} = useActivityDetails(id);

    if (!data) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="rounded-md p-4 max-w-md w-full mx-auto">
                    <div className="animate-pulse flex space-x-4">
                        <div className="rounded-full bg-slate-200 h-20 w-20"></div>
                        <div className="flex-1 space-y-6 py-1">
                            <div className="h-4 bg-slate-200 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="h-4 bg-slate-200 rounded col-span-2"></div>
                                    <div className="h-4 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div className="h-4 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="md:flex md:items-center md:justify-between md:space-x-5">
                    <div className="flex items-start space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                <EffortSquare effort={data.effort} size={EffortSquareSize.BIG}/>
                            </div>
                        </div>
                        <div className="pt-1.5">
                            <h1 className="text-2xl font-bold text-gray-900">{mapName(data.type)}</h1>
                            <p className="text-sm font-medium text-gray-500">
                                By{' '}
                                <span className="text-gray-900">
                            {data.user?.firstName} {data.user?.lastName}
                        </span>{' '}
                                on {dayjs(data.startDate).format("LLL")}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                        <button
                            onClick={() => showAlert("Create blocked!", "Please download mobile app to manage your data.", AlertType.DANGER)}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Edit
                        </button>
                        <button
                            onClick={() => showAlert("Create blocked!", "Please download mobile app to manage your data.", AlertType.DANGER)}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md bg-metcon px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                            Compare
                        </button>
                    </div>
                </div>

                <div className="my-8">
                    <ActivityChart activity={data}/>
                </div>

                <div className="my-8">
                    <ActivityDetails activity={data}/>
                </div>

                {/*<div className="grid grid-cols-2 gap-4">*/}
                {/*    <div className="my-8">*/}
                <div className="flex items-center justify-center">
                    <ActivityZoneGraph activity={data}/>
                </div>
                    {/*</div>*/}
                {/*</div>*/}

            </div>
        </main>
    );
}

export default Activity;