import {AlertType, useAlert} from "../../../providers/alertProvider";

const SelectBoard = () => {
    const {showAlert} = useAlert()

    return (
        <div className="text-center">
            <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
            >
                <path
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                />
            </svg>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No board selected</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by selecting board from list.</p>
            <div className="mt-6">
                <button
                    onClick={() => showAlert("Create blocked!", "Please download mobile app to manage your data.", AlertType.DANGER)}
                    type="button"
                    className="inline-flex items-center rounded-md bg-metcon px-3 py-2 text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                    New Board
                </button>
            </div>
        </div>
    )
}

export default SelectBoard;