import {Footer} from "../components/landing/layout/Footer";
import {Header} from "../components/landing/layout/Header";
import {Hero} from "../components/landing/Hero";
import {CallToAction} from "../components/landing/CallToAction";
import React from "react";

const Home = () => {
    return (
        <>
            <Header />
            <Hero />
            <CallToAction />
            <Footer />
        </>
    );
}

export default Home;