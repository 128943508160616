import {Activity} from "../../../providers/readingsTypes";
import dayjs from "dayjs";

export interface ActivityDetailsProps {
    activity: Activity;
}

const ActivityDetails = ({activity}: ActivityDetailsProps) => {
    const startTime = activity.readings[0].time;
    const endTime = activity.readings[activity.readings.length - 1].time;
    const duration = dayjs(dayjs(endTime).diff(startTime, 'milliseconds')).format("hh:mm:ss")

    const stats = [
        { name: 'Duration', value: duration },
        { name: 'Average heart rate', value: (activity.valueHolder.readingsSum / activity.valueHolder.readingsCount).toFixed(0), unit: 'bpm' },
        { name: 'Maximum heart rate', value: activity.valueHolder.readingsMax, unit: 'bpm' },
        { name: 'Distance', value: activity.distance || 0, unit: 'km' },
        { name: 'Energy Burned', value: activity.energy || 0, unit: 'kcal' },
    ];

    return (
        <div className="bg-gray-200 rounded">
            <div className="mx-auto max-w-7xl">
                <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 md:grid-cols-2">
                    {stats.map((stat) => (
                        <div key={stat.name} className="bg-gray-200 px-4 py-6 sm:px-6 lg:px-8">
                            <p className="text-sm font-medium leading-6 text-gray-600">{stat.name}</p>
                            <p className="mt-2 flex items-baseline gap-x-2">
                                <span className="text-4xl font-semibold tracking-tight text-gray-700">{stat.value}</span>
                                {stat.unit ? <span className="text-sm text-gray-500">{stat.unit}</span> : null}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ActivityDetails;