import {useParams} from "react-router-dom";
import {useGroupMembers} from "../../providers/readingsApi";
import {AlertType, useAlert} from "../../providers/alertProvider";

const Group = () => {
    const {showAlert} = useAlert()
    const {id} = useParams();
    const {data} = useGroupMembers(id || "");
    return (
        <main className="py-10 lg:pl-72">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Members</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the members in your group.
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button
                                onClick={() => showAlert("Create blocked!", "Please download mobile app to manage your data.", AlertType.DANGER)}
                                type="button"
                                className="block rounded-md bg-metcon px-3 py-2 text-center text-sm font-semibold text-black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                            >
                                Add member
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Name
                                        </th>
                                        {/*<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">*/}
                                        {/*    Title*/}
                                        {/*</th>*/}
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Status
                                        </th>
                                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Box
                                        </th>
                                        {/*<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">*/}
                                        {/*    <span className="sr-only">Edit</span>*/}
                                        {/*</th>*/}
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {(data || []).map((person) => (
                                        <tr key={person.user.id}>
                                            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                <div className="flex items-center">
                                                    <div className="h-11 w-11 flex-shrink-0">
                                                        <img className="h-11 w-11 rounded-full" src={person.user.avatar} alt="" />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-medium text-gray-900">{person.user.firstName} {person.user.lastName}</div>
                                                        <div className="mt-1 text-gray-500">Effort: <span
                                                            className="font-medium text-gray-800">{person.effort.toFixed(1)}</span> |
                                                            Recovery: <span
                                                                className="font-medium text-gray-800">{person.recovery.toFixed(0)}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            {/*<td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">*/}
                                            {/*    <div className="text-gray-900">{person.user.box}</div>*/}
                                            {/*    <div className="mt-1 text-gray-500">{person.user.box}</div>*/}
                                            {/*</td>*/}
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Active
                      </span>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{person.user.box}</td>
                                            {/*<td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">*/}
                                            {/*    <a href="#" className="text-indigo-600 hover:text-indigo-900">*/}
                                            {/*        Edit<span className="sr-only">, {person.user.box}</span>*/}
                                            {/*    </a>*/}
                                            {/*</td>*/}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Group;