import BoardMenuItem from "./BoardMenuItem";
import {useBoards} from "../../../providers/boardApi";
import MobileBoardList from "./MobileBoardList";

const BoardList = () => {
    const {data} = useBoards({});


    return (
        <>
            <MobileBoardList list={data}/>
            <aside
                className="fixed bg-gray-50 inset-y-0 left-72 hidden w-96 overflow-y-auto border-r border-gray-200 py-6 xl:block">
                <header
                    className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 lg:px-8">
                    <h2 className="text-base font-semibold leading-7">Board list</h2>
                </header>
                <div className="flex items-center justify-between border-b border-white/5 px-4 sm:px-6 lg:px-8">
                    <div role="list" className="divide-y divide-gray-100">
                        {(data || []).map((board) => <BoardMenuItem board={board} key={board._id}/>)}
                    </div>
                </div>
            </aside>
        </>
    );
}

export default BoardList;