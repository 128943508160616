interface DividerProps {
    text: string;
    bgColor?: string;
}

const Divider = ({text, bgColor = "bg-white"}: DividerProps) => {
    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300"/>
            </div>
            <div className="relative flex justify-center">
                <span className={`${bgColor} px-2 text-sm text-gray-500`}>{text}</span>
            </div>
        </div>
    )
}

export default Divider;