import { useQuery } from "react-query";
import {readingsClient} from "./apiClients";
import {Activity, ActivityDto, EffortDto, EffortLeaderboardDto, GroupDto, UserStatistics} from "./readingsTypes";

export enum READINGS_API_KEYS {
    GROUP_LIST = "GROUP_LIST",
}

export const useUserStatistics = (userId = null) =>
    useQuery<UserStatistics>(
        `stats`,
        async () => readingsClient.get(userId ? `/statistics?userId=${userId}` : "/statistics").then(data => data.data),
        {
            refetchOnMount: false
        }
    );

export const useCalendar = () =>
    useQuery<EffortDto[]>(
        `calendar`,
        async () => readingsClient.get( 'effort/calendar/21').then(data => data.data),
        {
            refetchOnMount: false
        }
    );

export const useActivityList = () =>
    useQuery<ActivityDto[]>(
        `activities`,
        async () => readingsClient.get(`/activity/list`).then(data => data.data),
        {
            refetchOnMount: false
        }
    );

export const useActivityDetails = (id: string) =>
  useQuery<Activity>(
    `activity-${id}`,
    async () =>
      readingsClient.get(`/activity/single/${id}`).then((data) => data.data),
    {
      refetchOnMount: false
    }
  );

//Group API

export const useGroups = () =>
  useQuery<GroupDto[]>(
    READINGS_API_KEYS.GROUP_LIST,
    async () => readingsClient.get(`/group/byUser`).then((data) => data.data),
    {
      refetchOnMount: false
    }
  );

export const useGroupMembers = (groupId: string, day = new Date()) => {
    const formattedDay = day.toISOString().split("T")[0];
    return useQuery<EffortLeaderboardDto[]>(
        `group-effort-${groupId}-${day}`,
        async () =>
            readingsClient
                .get(`/group-effort`, { params: { day: formattedDay, groupId } })
                .then((data) => data.data),
        {
            refetchOnMount: false
        }
    );
};