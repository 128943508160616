import { boardClient } from "./apiClients";
import { useInfiniteQuery, useQuery } from "react-query";
import {BoardDto, BoardFiltersParams} from "./boardTypes";

export const useBoards = (filters: BoardFiltersParams) =>
  useQuery<BoardDto[]>(
    `boards`,
    async () => boardClient.get(`/boards/all/access`, { params: filters }).then(data => data.data),
    {
      refetchOnMount: false
    }
  );

export const useDays = (boardId: string) =>
  useInfiniteQuery({
    queryKey: `days-${boardId}`,
    queryFn: async ({ pageParam = 0 }) =>
      boardClient
        .get(`days/${boardId}?page=${pageParam}&size=21`)
        .then((res) => res.data),
    getNextPageParam: (lastPage, allPages) => allPages.length
  });