import {createBrowserRouter} from "react-router-dom";
import Home from "../views/Home";
import Dashboard from "../views/dashboard/Dashboard";
import Start from "../views/dashboard/Start";
import React from "react";
import Boards from "../views/dashboard/Boards";
import Login from "../views/Login";
import Activities from "../views/dashboard/Activities";
import Group from "../views/dashboard/Group";

export const router = createBrowserRouter([
    {
        path: "/",
        element: (<Home />),
    },
    {
        path: "/login",
        element: (<Login />),
    },
    {
        path: "dashboard",
        element: (<Dashboard><Start /></Dashboard>),
    },
    {
        path: "dashboard/boards",
        element: (<Dashboard><Boards /></Dashboard>),
    },
    {
        path: "dashboard/boards/:id",
        element: (<Dashboard><Boards /></Dashboard>),
    },
    {
        path: "dashboard/activities",
        element: (<Dashboard><Activities /></Dashboard>),
    },
    {
        path: "dashboard/activities/:id",
        element: (<Dashboard><Activities /></Dashboard>),
    },
    {
        path: "dashboard/group/:id",
        element: (<Dashboard><Group /></Dashboard>),
    },
]);