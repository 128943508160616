import Sidebar from "../../components/dashboard/layout/Sidebar";
import {PropsWithChildren} from "react";

type DashboardProps = PropsWithChildren<{

}>

const Dashboard = ({children}: DashboardProps) => {
    return (
        <>
            <Sidebar/>
            {children}
        </>
    )
}

export default Dashboard;